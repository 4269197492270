import React, { useCallback, useState } from 'react'
import { Box, Heading, Button, HStack, Stack } from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { BiImage } from 'react-icons/bi'
import {
  LANG_EN_SHORT,
  LANG_HY_SHORT,
  LANG_RU_SHORT,
  useTranslation,
} from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { ReactComponent as Add } from 'assets/icons/add.svg'
import FileUpload from 'components/FileUpload'
import { useProject } from '../ProjectContext'
import BottomBar from '../BottomBar'
import useCustomToast from 'hooks/useCustomToast'
import { useFieldArray, useForm } from 'react-hook-form'
import { TextAreaComponent } from 'components/TextAreaComponent'
import { useObjectManipulations } from 'hooks'

const Rewards = () => {
  const { project, onUpdate } = useProject()
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { deleteKey } = useObjectManipulations()
  const [toastCoolDown, setToastCoolDown] = useState()
  const languages = [LANG_EN_SHORT, LANG_RU_SHORT, LANG_HY_SHORT]
  const camelCaseTranslations = {
    [LANG_EN_SHORT]: 'English',
    [LANG_HY_SHORT]: 'Armenian',
    [LANG_RU_SHORT]: 'Russian',
  }

  const [isLoading, toggleLoading] = useState(false)
  const {
    register,
    setValue,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      perks: project?.perks ?? [],
    },
  })

  const {
    fields: perks,
    append: appendPerk,
    remove: removePerk,
  } = useFieldArray({
    control,
    name: 'perks',
  })

  const onSubmit = useCallback(
    (data) => {
      const input = {
        id: project.id,
        perks: data.perks,
      }
      deleteKey(input, '__typename')
      onUpdate(input)
    },
    [project, perks]
  )

  const onError = (err) => {
    console.log('❌', err)
    const filteredErrors = err.perks.filter((e) => e)
    let thereIsEmptyField
    let thereIsLanguageError

    filteredErrors.forEach((error) => {
      const keys = Object.keys(error)
      keys.forEach((key) => {
        if (error[key].type === 'required') {
          thereIsEmptyField = true
        }
        if (error[key].type === 'pattern') {
          thereIsLanguageError = error[key].message
        }
      })
    })

    if (thereIsLanguageError && !toastCoolDown) {
      toast({
        title: thereIsLanguageError,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      setToastCoolDown(true)
      setTimeout(() => {
        setToastCoolDown(false)
      }, 5000)
    }
    if (thereIsEmptyField) {
      toast({
        title: t('Please fill all fields'),
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Heading mb="3" fontSize="5xl" as="h2">
        {t('project@rewards')}
      </Heading>
      <HStack spacing="8">
        <Box fontSize="xl" as="span">
          {t('createEditProject@addNewReward')}
        </Box>
        <Button
          isDisabled={isLoading}
          onClick={() => {
            appendPerk({
              id: uuid(),
              title: '',
              image: '',
              price: 0,
            })
          }}
          minH="28px"
          height="28px"
          w="28px"
          minW="28px"
          bg="blue.400"
          borderRadius="100%"
          variant="transparent"
        >
          <Add />
        </Button>
      </HStack>
      <Stack maxW="474px" my="8" spacing="8">
        {perks?.map((perk, index) => (
          <Stack
            key={`title-${perk.id}-index-${index}`}
            py="8"
            borderTop="1px solid"
            borderColor="border.100"
            spacing="8"
          >
            {languages.map((lang) => (
              <React.Fragment key={lang}>
                <InputField
                  key={t(`${camelCaseTranslations[lang]} title`)}
                  maxLength="100"
                  label={t(`${camelCaseTranslations[lang]} title`)}
                  size="sm"
                  isInvalid={errors?.perks?.[index]?.[`title_${lang}`]}
                  {...register(`perks[${index}].title_${lang}`, {
                    required: t('createEditProject@thisFieldIsRequired'),
                  })}
                />
                <TextAreaComponent
                  description={t(`${camelCaseTranslations[lang]} description`)}
                  isInvalid={errors?.perks?.[index]?.[`description_${lang}`]}
                  maxLength={100}
                  {...register(`perks[${index}].description_${lang}`, {
                    required: t('createEditProject@thisFieldIsRequired'),
                    maxLength: 100,
                  })}
                />
              </React.Fragment>
            ))}
            <InputField
              _focus={{ borderColor: 'blue.300' }}
              _hover={{ borderColor: 'blue.300' }}
              sx={{ boxShadow: 'none !important' }}
              borderColor="border.100"
              pattern="[0-9]*"
              size="sm"
              label={t('createEditProject@rewardPrice')}
              leftText="AMD"
              isInvalid={errors?.perks?.[index]?.price}
              fontSize="lg"
              {...register(`perks[${index}].price`, {
                required: t('createEditProject@thisFieldIsRequired'),
                validate: {
                  positiveNumber: (value) =>
                    value > 0 || t('createEditProject@thisFieldIsRequired'),
                },
                onChange: (e) => {
                  const sanitizedValue = e.target.value
                    .replace(/[^0-9]/g, '')
                    .replace(/^0+/, '')
                  setValue(`perks[${index}].price`, sanitizedValue)
                },
              })}
            />
            <FileUpload
              isLoading={isLoading}
              toggleLoading={toggleLoading}
              accept={'image/*'}
              imageUrl={perks[index].image}
              onUpload={(key) => {
                setValue(`perks[${index}].image`, key)
                clearErrors(`perks[${index}].image`)
              }}
              {...register(`perks[${index}].image`, {
                required: t('createEditProject@thisFieldIsRequired'),
              })}
            >
              <HStack spacing="2" align="center">
                <BiImage size={20} />
                <Box
                  fontSize="sm"
                  cursor="pointer"
                  ml="2"
                  as="span"
                  color={errors?.perks?.[index]?.image ? 'red.400' : 'black'}
                  fontWeight="regular"
                >
                  {t('createEditProject@uploadImage')}
                </Box>
              </HStack>
            </FileUpload>
            <Button
              onClick={() => removePerk(index)}
              color="red"
              variant="transparent"
            >
              {t('remove')}
            </Button>
          </Stack>
        ))}
      </Stack>
      <BottomBar />
    </form>
  )
}
export default Rewards
