// @TODO: uncomment PopUpProvider when it's ready to use
import React from 'react'
import { ScreenSizeProvider, UserProvider } from './contexts'
import { TranslationProvider } from './contexts/TranslationContext'
import { SettingsProvider } from './contexts/CurrencySettingsContext'
import Routes, { RouterProvider } from './pages'
import {
  QueryProvider,
  ChakraProvider,
  ModalProvider,
  // PopUpProvider,
} from './providers'
import GoogleAnalyticsWrapper from './components/GoogleAnalyticsWrapper'
import { GiftCardContextProvider } from 'contexts/GiftCardContext'
import { BasketProvider } from 'contexts/BasketContext'
import { QueryParamProvider } from 'contexts/QueryParamContext'
import AmplitudeProvider from 'providers/AmplitudeProvider'
import MessengerWrapper from 'components/MessengerWrapper'
import { HelmetProvider } from 'react-helmet-async'

function App() {
  return (
    <AmplitudeProvider>
      <ChakraProvider>
        <QueryProvider>
          <RouterProvider>
            <HelmetProvider>
              <QueryParamProvider>
                <GoogleAnalyticsWrapper>
                  <ScreenSizeProvider>
                    <TranslationProvider>
                      <UserProvider>
                        <SettingsProvider>
                          <GiftCardContextProvider>
                            <BasketProvider>
                              <ModalProvider>
                                {/* <PopUpProvider> */}
                                <MessengerWrapper>
                                  <Routes />
                                </MessengerWrapper>
                                {/* </PopUpProvider> */}
                              </ModalProvider>
                            </BasketProvider>
                          </GiftCardContextProvider>
                        </SettingsProvider>
                      </UserProvider>
                    </TranslationProvider>
                  </ScreenSizeProvider>
                </GoogleAnalyticsWrapper>
              </QueryParamProvider>
            </HelmetProvider>
          </RouterProvider>
        </QueryProvider>
      </ChakraProvider>
    </AmplitudeProvider>
  )
}

export default App
